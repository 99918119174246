@import '~theme/variables';
@import '../index.module.scss';

.primaryMenuList {
  display: flex;
  align-items: center;
  gap: $space-xxs;
}

.primaryMenuList {
  display: flex;
  align-items: center;
  gap: $space-xxs;
}

.rightNavContainer {
  display: flex;

  align-items: center;
}

.primary {
  @include breakpoint(md) {
    display: none;
  }
}

.more {
  text-decoration: none;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: initial;

  &:hover {
    svg {
      fill: $color-neutral-01;
    }
  }

  span {
    font-weight: 500;
  }

  svg {
    transition: transform $transition-01;
    margin-left: $space-xxs;
    fill: var(--text-color);
  }

  &[data-state='open'] {
    color: $color-neutral-01;
    background-color: $color-neutral-06;
    svg {
      transform: rotate(180deg);
      fill: $color-neutral-01;
    }
  }
}

.linkItem {
  padding: $space-s $space-m;
  border-radius: $border-radius-02;
  transition: background-color $transition-01;
  color: var(--text-color);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8rem;
  position: relative;

  &:hover {
    background-color: var(--hover-bg-color);
    color: $color-neutral-01;
  }

  &.active::after {
    content: '';
    transition: background-color $transition-01;
    width: calc(100% - 4.8rem);
    height: 0.1rem;
    background-color: var(--text-color);
    position: absolute;
    left: 2.4rem;
    bottom: 0.8rem;
  }

  &:hover {
    background-color: var(--hover-bg-color);
    color: $color-neutral-01;
    &.active::after {
      background-color: transparent;
    }
  }
}

.secondaryMenuWrapper {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 1.2rem;
    left: 3rem;
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid $color-neutral-06;
  }
}

.secondaryMenuList {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  padding: $space-xs;
  width: auto;
  border-radius: $border-radius-02;
  background-color: $color-neutral-06;
  box-shadow: 0px 4px 4px 0px #00000040;
  max-height: 70vh;
  overflow: auto;
  gap: $space-xxs;

  .link {
    // padding: $space-s $space-s;
    border-radius: $border-radius-02;
  }
}

.secondaryListItem {
  .linkItem {
    padding: $space-s;
    white-space: nowrap;
    color: $color-neutral-01;
    width: 100%;
    display: flex;
    &:hover {
      color: $color-neutral-01;
      background-color: $color-n300;
    }
  }
}
