@import '~theme/variables';

.card {
  color: black;
  width: 10.4rem;
  height: 10.4rem;
  padding: 1.2rem;
  padding-top: 0.6rem;
  margin: 1rem;
  border: 1px solid $color-neutral-04;
  border-radius: $border-radius-03;
  background-color: $color-neutral-06;
  box-shadow: $box-shadow-01;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  transition: box-shadow $transition-01, background-color $transition-01,
    border $transition-01;

  word-wrap: break-word;
  &:hover {
    background-color: $color-neutral-01;
    border-color: $color-neutral-01;
    box-shadow: $box-shadow-01;
    text-decoration: none;

    .cardTitle {
      color: $color-neutral-06;
    }
    .description {
      color: $color-neutral-04;
    }
    .cardIcon path {
      fill: $color-neutral-06;
    }
  }

  @include breakpoint(sm) {
    .description {
      display: block;
    }
    .cardIcon {
      width: 5.6rem;
      height: 5.6rem;
    }
    line-height: 1.4rem;
    min-width: 18rem;
    min-height: 18rem;
    gap: 1rem;
  }
}

.link {
  text-decoration: none;
}

.description {
  color: $color-neutral-02;
  display: none;
  font-size: $small-font-size;
  transition: background-color $transition-01;
}

.cardTitle {
  line-height: 2rem;
  font-size: $h5-font-size;
  transition: background-color $transition-01;
}

.cardIcon {
  width: 4.8rem;
  height: 4.8rem;
  transition: background-color $transition-01;
}
