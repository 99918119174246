@import '~theme/variables';

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-03;
  overflow: hidden;
  box-shadow: $box-shadow-01;
}

.image {
  min-width: 100%;
  object-fit: cover;

  img {
    width: 100%;
  }
}

.content {
  position: relative;
  padding: 1.4rem 1.6rem 1.6rem 1.6rem;
}

.title {
  display: inline-block;
  padding-right: 4rem;

  &:hover {
    a {
      text-decoration: underline;
      text-decoration-color: $color-neutral-01;
    }
  }
}

.geoDistance {
  position: absolute;
  top: 1.4rem;
  right: 1.6rem;
  color: $color-neutral-02;
}

.cta {
  position: relative;
  margin-right: 1.6rem;
  display: inline-block;
}

.footerContent {
  margin-top: 0.8rem;
  border-radius: $border-radius-02;
  display: inline-block;
}

.noBorder {
  border: 0;
  border-radius: 0;
}

.distanceContainer {
  display: flex;
  margin-bottom: 0.8rem;
}

.marker {
  fill: $color-neutral-02;
  font-size: 1.6rem;
  margin-right: 0.4rem;
}

.distanceDescription {
  margin-top: 0.1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}

.price {
  margin-left: 0.4rem;
}

.subscript {
  position: relative;
  display: inline-block;
  top: -0.8rem;
  left: -0.3rem;
}

.address {
  line-height: 1.6rem;
}

.button {
  padding: 0.9rem;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  border-radius: $border-radius-02;
  transition: 0.2s ease-in-out;
  font-size: 1.3rem;
  font-weight: 700;
  white-space: nowrap;

  + .button {
    margin-left: 0.8rem;
  }
}

.invertedAlternative {
  color: $color-neutral-06;
  border-color: $color-neutral-01;
  background-color: $color-neutral-01;
  border: 0.1rem solid $color-neutral-01;

  &:hover {
    background-color: $color-neutral-06;
    color: $color-neutral-01;
  }
}

.inverted {
  color: $color-neutral-01;
  background-color: $color-neutral-06;
  border: 0.1rem solid $color-neutral-01;

  &:hover {
    background-color: $color-neutral-01;
    color: $color-neutral-06;
  }
}

.openingSoon {
  background-color: $color-neutral-01;
  color: $color-neutral-06;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.6rem;
  padding: $space-xs;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}
