@import "~theme/variables";

.title {
  margin-bottom: $space-l;

  @include breakpoint(sm, max) {
    margin-bottom: $space-m;
  }
}

.container {
  display: grid;
  gap: $space-l;
  grid-template-columns: 1fr;

  @include breakpoint(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.map {
  flex: 1;
  @include breakpoint(sm, max) {
    aspect-ratio: 1;
  }

  @include breakpoint(lg) {
    grid-column: span 2;
  }
}
