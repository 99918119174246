@import '~theme/variables';

.section {
  background-color: $color-neutral-05;
  height: 100%;
  min-height: 70rem;
  margin-bottom: 0;
  align-items: center;
  flex: 0 0 100%;
  padding-top: 6rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.columnCenter {
  display: flex;
  align-items: center;
}

.row {
  margin-bottom: $space-m;
  width: 100%;
  text-align: center;
}

.imageRow {
  margin-bottom: $space-xl;

  @include breakpoint(sm, max) {
    img {
      width: 10rem;
    }
  }
}

.textCenter {
  white-space: pre-line;
  text-align: center;
}

.title {
  font-family: $font-family-display;
  font-size: 4.8rem;
  line-height: 85%;

  @include breakpoint(md) {
    font-size: 7.9rem;
  }
}

.imageWrapper {
  margin: $space-xl;
  @include breakpoint(sm) {
    margin: $space-l;
  }
}

.textLH {
  line-height: 2;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 5rem;

  @include breakpoint(md) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
